<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <div class="flex-col items-center w-full px-24 py-10 bg-white rounded-xl">
    <div>
      <span class="text-3xl stroke-1">Confirm Distribution</span>
    </div>
    <div class="mb-5">
      <span v-if="option === 'sms'" class="pt-1 text-lg">
        Your notification will be sent as an SMS. Confirm your distribution
        filtering options below.
      </span>
      <span v-if="option === 'push_notification'" class="pt-1 text-lg">
        Your notification will be sent as Push Notification. Confirm your
        distribution filtering options below.
      </span>
    </div>
    <div
      class="px-5 py-5 pt-2 text-left text-white bg-qgo-primary-bg rounded-3xl"
    >
      {{ marketingMessage }}
    </div>
    <div>
      <ul class="flex mt-2 border-b list-reset">
        <li
          :class="[
            activeTab === 'last_7days'
              ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
              : 'mr-1',
          ]"
        >
          <a
            class="inline-block px-4 py-2 text-sm"
            :class="activeTab === 'last_7days' ? 'bg-white' : 'bg-gray-200'"
            href="#"
            @click="selectActiveTab('last_7days')"
          >
            Last 7 Days
          </a>
        </li>
        <li
          :class="[
            activeTab === 'last_30days'
              ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
              : 'mr-1',
          ]"
        >
          <a
            class="
              inline-block
              px-4
              py-2
              text-sm text-blue
              hover:text-blue-darker
            "
            :class="activeTab === 'last_30days' ? 'bg-white' : 'bg-gray-200'"
            href="#"
            @click="selectActiveTab('last_30days')"
          >
            Last 30 Days
          </a>
        </li>
        <li
          :class="[
            activeTab === 'last_90days'
              ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
              : 'mr-1',
          ]"
        >
          <a
            class="
              inline-block
              px-4
              py-2
              text-sm text-blue
              hover:text-blue-darker
            "
            :class="activeTab === 'last_90days' ? 'bg-white' : 'bg-gray-200'"
            href="#"
            @click="selectActiveTab('last_90days')"
          >
            Last 90 Days
          </a>
        </li>
        <li
          :class="[
            activeTab === 'all_customers'
              ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
              : 'mr-1',
          ]"
        >
          <a
            class="inline-block px-4 py-2 text-sm text-grey-light"
            :class="activeTab === 'all_customers' ? 'bg-white' : 'bg-gray-200'"
            href="#"
            @click="selectActiveTab('all_customers')"
          >
            All Customers
          </a>
        </li>
      </ul>
    </div>
    <div class="flex flex-row pt-5">
      <div class="flex w-full">Send to {{ count ? count : 0 }} Customers</div>
      <div v-if="option === 'sms'" class="flex-1 font-bold">
        ${{ parseFloat((count ? count : 0) * 0.07).toFixed(2) }}
      </div>
    </div>
    <div class="pt-5">
      <button
        v-if="option === 'sms'"
        @click="sendAsSms"
        class="
          w-full
          py-3
          text-white
          border-b-2
          sm:rounded-lg
          bg-qgo-primary-bg
        "
      >
        Confirm & Send as SMS
      </button>
      <button
        v-if="option === 'push_notification'"
        @click="sendAsPushNotification"
        class="
          w-full
          py-3
          text-white
          border-b-2
          sm:rounded-lg
          bg-qgo-secondary-bg
        "
      >
        Confirm & Send as Push Notification
      </button>
    </div>
    <div class="pt-2">
      <button
        @click="cancelMsg"
        class="w-full py-3 bg-gray-400 border-b-2 sm:rounded-lg"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";
// import axios from "axios";

export default defineComponent({
  emits: ["messageSent"],
  methods: {
    sendAsSms() {
      if (!parseInt(this.count)) {
        this.$swal.fire({
          icon: "error",
          title: "No users to send sms",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
        return;
      }
      // send as sms algo
      // let customer = this.count.map(customer => {
      //   return customer.phone_number;
      // });
      this.isLoading = true;
      let price = localStorage.getItem("smsprice");
      if (price) {
        price = parseFloat(price);
        let body = {
          amount: parseFloat(parseFloat(this.count) * price),
          customer_of: this.activeTab,
          message: this.marketingMessage,
        };
        this.$http
          .post("message/sendsms", body)
          .then((response) => {
            this.$emit("messageSent", response.data.data);
            this.$store.commit("marketing/setMarketingMessage", "");
            this.$store.commit("marketing/setMsgModal", false);
            this.isLoading = false;
            // this.$store.dispatch("marketing/fetchMarketingInitialData");
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            console.error("There was an error!", error);
          });
      }
    },
    sendAsPushNotification() {
      if (!parseInt(this.count)) {
        this.$swal.fire({
          icon: "error",
          title: "No users to send push notification",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
        return;
      }
      this.isLoading = true;
      let body = {
        customer_of: this.activeTab,
        message: this.marketingMessage,
      };
      this.$http
        .post("message/sendpush", body)
        .then((response) => {
          this.$emit("messageSent", response.data.data);
          this.$store.commit("marketing/setMarketingMessage", "");
          this.$store.commit("marketing/setMsgModal", false);
          this.isLoading = false;
          // this.$store.dispatch("marketing/fetchMarketingInitialData");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          console.error("There was an error!", error);
        });
    },
    cancelMsg() {
      this.$store.commit("marketing/setMsgModal", false);
    },
    selectActiveTab(val) {
      console.log("active" + val);
      this.$store.commit("marketing/setActiveTab", val);
      this.getCustomerCount(val);
    },
    getCustomerCount(tab) {
      let data = {
        tab: tab,
        option: this.$store.getters["marketing/option"],
      };
      this.$store.dispatch("marketing/fetchCountCustomers", data).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong while fetching customer count!",
            timer: 5000,
          });
        }
      );
    },
  },
  computed: {
    marketingMessage() {
      return this.$store.getters["marketing/marketingMessage"];
    },
    activeTab() {
      return this.$store.getters["marketing/activeTab"];
    },
    count() {
      return this.$store.getters["marketing/count"];
    },
    option() {
      return this.$store.getters["marketing/option"];
    },
  },
  mounted() {
    this.getCustomerCount(this.activeTab);
  },
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
    };
  },
});
</script>
